<template>
  <div class="voteForm">
    <div class="top-btn">
      <v-button text="返回" @click="goBack"></v-button>
    </div>
    <div class="bottom-wrap">
      <v-tabs :tabs="tabs" v-model="activeName"></v-tabs>
      <base-info
        v-show="activeName === 'base'"
        :id="id"
        :isView="isView"
        @updateData="baseSuccessCB"
        sortLabel="投票排序"
      ></base-info>
      <answer-info
        ref="answerInfo"
        :id="id"
        :isView="isView"
        :saveKey="saveKey"
        :answerCount="answerCount"
        v-show="activeName === 'answer'"
        @view="viewCB"
      ></answer-info>
    </div>
    <question-view
      :isPreviewShow.sync="isPreviewShow"
      :detail="detail"
    ></question-view>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import BaseInfo from "./components/baseInfo.vue";
import AnswerInfo from "./components/answerInfo";
import questionView from "./components/questionView.vue";

export default {
  name: "voteForm",
  components: {
    BaseInfo,
    AnswerInfo,
    questionView,
  },
  data() {
    return {
      id: undefined,
      tabs: [
        {
          label: "基础信息",
          disabled: false,
          name: "base",
        },
        {
          label: "问题信息",
          disabled: false,
          name: "answer",
        },
      ],
      detail: {
        questionList: [],
      },
      saveKey: 0,
      isPreviewShow: false,
      answerCount: 0,
      isView: false,
      activeName: "base",
    };
  },
  created() {
    const { id, isView } = this.$route.query;
    if (id !== undefined) {
      this.id = id;
    }
    if (isView !== undefined) {
      this.isView = JSON.parse(isView);
    }
    this.tabs[1].disabled = this.id === undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 点击预览按钮
    viewCB(list) {
      this.isPreviewShow = true;
      this.detail.questionList = list;
    },
    // 基础信息成功保存后的回调
    baseSuccessCB(d, onOff) {
      const data = _.cloneDeep(d);
      this.detail = { ...data, questionList: this.detail.questionList };
      if (onOff) {
        this.activeName = "answer";
      }
      this.id = data.id;
      this.isView = moment(data.endDate).isBefore(new Date());
      console.log(this.isView, "isView");
      this.answerCount = data.answerCount;
      this.tabs[1].disabled = false;
      this.saveKey += 1;
    },
  },
};
</script>
<style lang="less" scoped>
.voteForm {
  box-sizing: border-box;
  height: 100%;
  .top-btn {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
  }
  .bottom-wrap {
    height: calc(100% - 50px);
  }
}
</style>
