//问卷主题列表
const getTopicListURL = `/gateway/hc-govern/manageapi/qu/topicList`;
//主题删除
const topicDeleteURL = "/gateway/hc-govern/manageapi/qu/topicDelete";
//问卷回答人列表
const getTopicAnswerListlURL = `/gateway/hc-govern/manageapi/qu/topicAnswerList`;
//主题详情
const getTopicInfo = "/gateway/hc-govern/manageapi/qu/topicInfo";
// 查询答题人列表
const getSteamAnswerListURL = "/gateway/hc-govern/manageapi/qu/steamAnswerList";
//主题增加|编辑
const topicAddOrEditURL = `/gateway/hc-govern/manageapi/qu/topicAddOrEdit`;
//题目列表
const questionListURL = `/gateway/hc-govern/manageapi/qu/questionList`;
//题干增加|编辑
const stemAddOrEditURL = `/gateway/hc-govern/manageapi/qu/stemAddOrEdit`;
//题干批量增加|编辑
const stemAddBatchURL = `/gateway/hc-govern/manageapi/qu/stemAddBatch`;
//题干删除
const stemDeleteURL = `/gateway/hc-govern/manageapi/qu/stemDelete`;
//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取建筑列表
// const getBuildListURL = `/gateway/hc-space/space/building-list`;
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;

// 产权人审核列表查询
const getHouseOwnerAuditListUrl = `/gateway/blade-user/user/getHouseOwnerAuditList`;
// 产权人审核批量通过
const batchApproveHouseOwnerAuditUrl = `/gateway/blade-user/user/batchApproveHouseOwnerAudit`;
// 产权人审核详情查询
const getHouseOwnerAuditDetailUrl = `/gateway/blade-user/user/getHouseOwnerAuditDetail`;
// 产权人审核详情保存
const examineHouseOwnerAuditUrl = `/gateway/blade-user/user/examineHouseOwnerAudit`;
export {
  getTopicListURL,
  topicDeleteURL,
  getTopicAnswerListlURL,
  getTopicInfo,
  getSteamAnswerListURL,
  topicAddOrEditURL,
  questionListURL,
  stemAddOrEditURL,
  stemAddBatchURL,
  stemDeleteURL,
  getCommunityListUrl,
  getBuildListURL,
  getHouseOwnerAuditListUrl,
  batchApproveHouseOwnerAuditUrl,
  getHouseOwnerAuditDetailUrl,
  examineHouseOwnerAuditUrl,
};
